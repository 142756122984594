<template>
  <div>
    <div class="comment">
      <div class="titleBox">
        <p>{{ '课程评价' }}</p>
      </div>
      <div class="contentBox" v-loading="com_loading">
        <div class="inputBox">
          <!-- <img :src="avatar ? avatar : DEFAULT_AVATAR" alt="" /> -->
          <el-input
              class="inputSty"
              placeholder="写下您对该课程的留言"
              size="small"
              v-model="input"
              maxlength="200"
              @keyup.enter.native="handleSend"
              clearable
          >
          </el-input>
          <div style="text-align: right;padding-top: 20px;">
            <span class="len_num">{{input.length}}/200</span>
            <el-button type="primary" size="small" style='width:94px' @click="handleSend">{{
                $t("published")
              }}</el-button>
          </div>
        </div>
        <div class="totalBox">
          {{ '共' }}{{ total }}{{ $t("comments") }}
        </div>
        <div class="listBox">
          <commentItem
              v-for="(item, i) in comment_list"
              :key="i"
              :item="item"
              @del="del_child"
          ></commentItem>
          <noDataImg v-if="comment_list.length == 0 && !com_loading" />
          <div class="paginationBlock" v-if="total != 0">
            <el-pagination
                @current-change="handleChange"
                :total="total"
                :hide-on-single-page="true"
                :page-size="5"
                layout="prev, pager, next"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commentItem from "./commentItem";
export default {
  components: {
    commentItem,
  },
  data() {
    return {
      video_id:"",
      company_id:"",
      avatar: "",
      com_loading: false,
      input: "",
      total: 0,
      start: 1,
      comment_list: [],
    };
  },
  mounted() {
    this.video_id=this._decode(this.$route.query.parameter).video_id
    this.getList();
  },
  methods: {
    async getList() {
      console.log(1111111111111111);
      this.com_loading = true;
      let params = {
        video_id: this.video_id,
        start: this.start - 1,
        limit: 5,
      };
      let result = await this.$store.dispatch(
          "API_company/association_video_comment",
          params
      );
      if (result && result.success) {
        this.comment_list =result.data;
        this.total = result.total;
      }
      this.com_loading = false;
    },
    handleChange(e) {
      this.start = e;
      this.getList();
    },
    async handleSend() {
      if(!this.USER_INFO.id){
        this.$router.push("/sign");
        return
      }
      if (this.input == "") {
        this.$message.warning(this.$t("entercontent"));
        return;
      }
      this.com_loading = true;
      let params = {
        content: this.input,
        user_id: this.USER_INFO.id,
        video_id:this.video_id,
      };
      console.log('params');
      let save = await this.$store.dispatch(
          "API_company/add_video_comment",
          params
      );
      if (save && save.success) {
        this.input = "";
        this.getList();
      }
    },
    del_child(e) {
      if(this.comment_list.length==1&&this.start>1){
        this.start=this.start-1
        this.getList();
      }else{
        this.getList();
      }


    },
  },
};
</script>

<style scoped lang="less">
.comment {
  width: 804px;
  background: #fff;
  margin-bottom: 12px;
  padding: 0 20px;
  .titleBox {
    font-size: 16px;
    font-weight: 600;
    line-height: 60px;
    border-bottom: 1px solid #dcdfe6;
  }
  .contentBox {
    .inputBox {
      margin-top: 16px;
      height: 118px;
      box-sizing: border-box;
      background: #F6F6F6;
      border-radius: 2px;
      padding: 16px;
      img {
        width: 36px;
        height: 36px;
      }
      .inputSty {
        margin: 0 12px 0 0;
      }
      .len_num{
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        display:inline-block;
        padding-right: 17px;
      }
    }
    .totalBox {
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      margin-top:16px;
    }
    .listBox {
      position: relative;
      min-height: 300px;
      .paginationBlock {
        text-align: right;
        margin-top: 15px;
        padding-bottom: 20px;
      }
    }
  }
}
</style>