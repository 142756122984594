<template>
  <div class="videoPlayComponents">
    <div
      class="prism-player"
      id="Ailyun_prismPlayer"
      v-show="hasAliyunId"
    ></div>
    <script type="text/template" id="endPreviewTemplate">
      <div class="endPreviewTemplate">
        <div>
           <div class="tips">试看已结束，如需观看全部视频，请联系秘书处：</div>
            <div class="scbaContactInfo">
              <div class="concatItem">
                入会联系：021-68892891
                </div>
                <div class="concatItem">
                业务咨询：021-68892892
                </div>
                <div class="concatItem">
                培训联系：021-68892894
                </div>
                <div class="concatItem">
                赞助联系：021-68892893
                </div>
                <div class="concatItem">
                跨境贸易分会联系：021-20953528
                </div>
                <div class="concatItem">
                空运分会联系：021-58102609
                </div>
            </div>
            <div class="knowBtn" data-click data-method="closePreviewTip">我知道了</div>
        </div>
      </div>
    </script>
    <script type="text/template" id="previewBarHtml">
      <span>如需观看全部视频，请联系秘书处</span>
    </script>
  </div>
</template>

<script>
export default {
  props: {
    AliyunId: {
      type: String,
      default: "",
    },
    autoplay: {
      //自动播放
      type: Boolean,
      default: true,
    },
    notShowAd:{
      type:Boolean,
      default:false
    }
    // pre_view_time: {//
    //   type: Number,
    //   default: 0,
    // },
  },
  data() {
    return {
      hasAliyunId: true,
      pre_view_time: 0,
    };
  },
  mounted() {
    this.handlePlay();
    if (this.PJSource == 13) {
      this.pre_view_time = 180;
    }
  },
  methods: {
    async handlePlay() {
      if (!this.AliyunId) {
        return;
      }
      let authResult = await this.getAuth();
      this.initPlayer(authResult);
    },
    async changeVideo() {
      if (!this.player) {
        this.handlePlay();
        return;
      } else {
        let result = await this.getAuth();
        this.player.replayByVidAndPlayAuth(this.AliyunId, result.data.PlayAuth);
      }
    },
    async getAuth() {
      try {
        let result = await this.$store.dispatch("baseStore/getPlayAuth", {
          aliyun_id: this.AliyunId,
        });
        if (result.success) {
          return result;
        }
      } catch (e) {}
    },

    initPlayer(result) {
      // var videoAdClose = function (videoAd) {
      //   videoAd.pauseVideoAd();
      //   videoAd.closeVideoAd();
      // };
      if (this.PJSource == 37  || this.notShowAd) {
        this.player = new Aliplayer(
          {
            id: "Ailyun_prismPlayer",
            width: "100%",
            height: "100%",
            autoplay: this.autoplay,
            language: this.IS_EN ? "en-us" : "zh-cn",
            vid: result && result.data.VideoMeta.VideoId,
            playauth: result && result.data.PlayAuth,
            cover: result && result.data.VideoMeta.CoverURL,
          }
        );
      } else {
        this.player = new Aliplayer(
          {
            id: "Ailyun_prismPlayer",
            width: "100%",
            height: "100%",
            autoplay: this.autoplay,
            language: this.IS_EN ? "en-us" : "zh-cn",
            vid: result && result.data.VideoMeta.VideoId,
            playauth: result && result.data.PlayAuth,
            cover: result && result.data.VideoMeta.CoverURL,
            components: [
              {
                name: "VideoADComponent",
                type: AliPlayerComponent.VideoADComponent,
                args: [
                  "https://vod.aila.site/customerTrans/bcd69f5462af799806a3517fab306996/2afd54b7-1771489a99f-0006-8568-b09-239a7.mp4",
                  "",
                  false,
                  this.$t("clickClose"),
                ],
              },
              {
                name: "PreviewVodComponent",
                type: AliPlayerComponent.PreviewVodComponent,
                args: [
                  this.pre_view_time,
                  `#endPreviewTemplate`,
                  "#previewBarHtml",
                ],
              },
            ],
          },
          (player) => {
            let eventDoms = document.querySelectorAll("[data-click]");
            eventDoms.forEach((item) => {
              item.addEventListener("click", () => {
                if (item.getAttribute("data-method") == "closePreviewTip") {
                  player
                    .getComponent("PreviewVodComponent")
                    .closePreviewLayer();
                } else {
                  this.$emit(item.getAttribute("data-method"));
                }
              });
            });
          }
        );
      }

      this.player.on("play", () => {
        this.$emit("videoPlay");
      });
      this.player.on("ended", () => {
        this.$emit("videoEnd");
      });
    },
    pauseVideo() {
      if (this.player) {
        this.player.pause();
      }
    },
  },
  watch: {
    AliyunId: {
      handler(nv) {
        if (nv) {
          this.hasAliyunId = true;
          this.$nextTick(() => {
            this.changeVideo();
          });
        } else {
          this.hasAliyunId = false;
          this.player.pause();
        }
      },
    },
  },
  destroyed() {},
};
</script>

<style scoped lang='less'>
.videoPlayComponents {
  height: 100%;
}
</style>
<style lang="less">
.scbacontacttips {
  color: #00c1de;
  margin-left: 2px;
}
.endPreviewTemplate {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .tips {
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .scbaContactInfo {
    margin: 0 auto;
    width: 500px;
    height: 100px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .concatItem {
      width: 230px;
      font-size: 14px;
      color: #fff;
    }
  }
  .knowBtn {
    width: 120px;
    padding: 7px 0px;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    margin: 0 auto;
    margin-top: 15px;
    cursor: pointer;
  }
}
</style>
<style  lang='less'>
.video-ad-component .video-ad-detail {
  display: none !important;
}
</style>